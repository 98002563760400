/* application variables */
$primary-dark-green: #00B140;
$primary-light-green: #66BD49;
$primary-grey-background: #fefefe;
$primary-offwhite-light: #F1F1F1;
$primary-offwhite-dark: #bab8b8;

$primary-opacity: 0.7;


$primary-color: #fff;
$primary-background-color: #FAFBFC;

$cool-blue: #48BEFF;
$cool-green: #17B890;
$cool-orange: #EFBC9B;
$cool-pink: #EE92C2;
$cool-red: #B95F89;
$cool-purple: #645E9D;
$cool-yellow: #FAFF7F;


$aqua: #5C9EAD;
$dark-aqua: #326273;
$dark-blue: #0471A6;
$dark-purple: #593F62;


$navigation-background-color: rgba(82, 84, 83, 1);
$navigation-overlay-color: rgba(93, 95, 94, 1);
$navigation-inactive-text-color: rgb(185, 191, 187);
/*$navigation-active-text-color: rgb(255,255,255);*/

$navigation-active-text-color: rgb(38,112,146);
