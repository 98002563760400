



$primary-font-family: 'Frank Ruhl Libre', sans-serif;



/*font-family: 'Handlee', cursive; liked this one 
font-family: 'Frank Ruhl Libre', sans-serif;
font-family: 'Lateef', cursive;
font-family: 'Goudy Bookletter 1911', serif;
font-family: 'The Girl Next Door', cursive;
font-family: 'Gilda Display', serif; liked this one
font-family: 'Caudex', serif;
font-family: 'Quando', serif;*/


$primary-font-weight: 400;


$mobilelandscape-font-weight: 300;