.IWS_ContentPage {
	width:100%;
	height:100%;
}



.page-contentpage {
	width:100%;
	height:100%;
	overflow-x:hidden;
	overflow-y:visible;
	position:relative;

	-webkit-overflow-scrolling: touch;
}

.page-contentpage .background {
	position:absolute;
	width:100%;
	height:100%;
	background-size: cover;
    background-repeat: no-repeat;
    background-position:center; 
}

.content-board {
	position: absolute; 
	transform-style: flat; 
	backface-visibility: hidden; 
	@include centerHorizontally();
	opacity: 1.0; 
	z-index: 10; 
	overflow: hidden; 
	width:95%;




	.main-button-wrapper {
		position:absolute;
		right: 15px;
    	top: 14px;
	}
}



.content-board .project-position {
	position: absolute; 
	transform-style: flat; 
	backface-visibility: hidden; 
	@include transform(translate3d(20px, 15px, 0px));
	span {
		display:block;
		font-size:14px;
	}
	a {
		font-size:14px;
		text-decoration: underline;
		cursor:pointer;
		color:white;
	}
}

.content-board .board-header {
	display: block; 
	position: relative; 
	left: 25%; 
	top:15px;
	font-size: 14px;
	span {
		position: absolute; 
		width: 43px; 
		height: 1px; 
		top: 10px; 
		left: 40px;
	}	
}

.content-board .project-title {
	position: absolute; 
	transform-style: flat; 
	backface-visibility: hidden; 
	left:25%;
	top: 75px; 
	font-size: 33px; 
	line-height: 35px;
	span {
		display:block;
	}
}

.content-board .project-lower {
	position:relative;
	width:100%;
	margin-top:300px;
	margin-bottom:100px;
	padding: 0px 20px;
	box-sizing:border-box;

	.project-brief {
		font-size:28px;
		top:355px;
		left:20px;
		width:75%;
		text-align:left;
		line-height:35px;
		
	}

	.project-stats {
		color: rgb(255, 255, 255); 
		font-size: 14px; 
		line-height: 27px; 
		white-space: nowrap;
	}

	.project-description {
		display:flex;
		flex-direction:row;
		margin-left:25%;
		margin-top:75px;
		div {
			font-size:18px;
			line-height: 1.75em; 
			font-weight:500;
		}
	}

	.project-story {
		width:50%;
		span {
			color: rgb(255, 255, 255); 
			font-size:18px;
			white-space: nowrap;
			margin-bottom:10px;
		}
		div {
			margin-top:10px;
			font-family: 'Raleway', sans-serif;
			font-size:13px;
		}
	}

	.project-skills { 
		width: 33%;
		line-height: 18px; 
		left: 372.75px; 
		color: rgb(255, 255, 255); 
		top: 0px;
		margin-left:50px;

		li {
			line-height: 1.75em;
			white-space: nowrap;
			font-family: 'Raleway', sans-serif;
			font-size:13px;
			font-weight:500;
			span {
				left:-0.5em;
				margin-bottom: 10px;
				position: relative;
			}
		}

		li:nth-of-type(1) {
			margin-top:10px;
		}
	}

	.project-externals {
		display:flex;
		flex-direction:column;
		margin-top:40px;

		a {
			color:white;
			margin:auto;
		    height: 28px;
		    border: solid 1px white;
		    border-radius:  5px;
		    line-height:  28px;
		    padding:  0px 20px;
		    margin-bottom:20px;
		}
	}

}





.project-content .discography {
	.board-header {
		span {
			margin-left:25px;
		}
	}
	.project-skills {
		display:none;
	}
	.project-position {
		display:none;
	}
	.project-title {
		top:22px;
		font-style: italic;
	}
	.project-lower {
		margin-top: 90px;
		.project-brief {
			font-style:italic;
		}
		padding: 0px;
		padding-left:25%;
		
	}
	.project-description {
		margin-top: 35px;
		margin-left:0px;
	}

	.project-externals {
		display: block;
		padding: 0px;
	}
	.project-externals a {
		border:none;
		white-space: nowrap;
		padding: 0px 20px 0px 0px;
	}
}





@media only screen and (max-width: 855px) { 
	.content-board .project-lower .project-description {
		margin-left:0px;
	}
	
}

@media only screen and (max-width: 550px) { 
	.content-board .project-title {
		left:20px;
		top:105px;
	}
	.content-board .project-lower .project-description {
		flex-direction:column;
		.project-skills {
			margin-left:0px;
			margin-top:40px;
		}

		.project-story {
			width:85%;
		}
	}
	.content-board .project-lower .project-brief {
		width:85%;
	}

	.content-board .project-lower .project-skills {
		width:85%;

		li {
			white-space:normal;
		}
	}


	.project-content .discography {

		.project-lower {
			padding-left:20px;
		}

		.project-externals {
			flex:none;
		}
		
	}

	
	
}

@media only screen and (max-width: 415px) { 
	.content-board .board-header {
		top:270px;
		left:20px;
	}

	.content-board .project-title {
		font-size:23px;
		left:20px;
		top:115px;
	}
	
}