.page-newabout {
    width:100%;
    height:100%;

    .parallax-background {
        filter: blur(4px);
    }

    .dark-overlay {
        position: absolute;
        width:100%;
        height:100%;
        background-color:black;
        opacity:0.5;
    }
    .content {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;    

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        p1 {
            text-align: center;
            font-size: 26px;
        }

        div {
            color:transparent;
            background-color: white;
            margin:20px 0px;
            height:1px;
            width:200px;
        }
    }
}

.subject-page-button {
    width:100px;
    height: 40px;
    background-color:transparent;
    border: solid 1px white;
    color:white;
}



@media only screen and (max-width: 1000px) { 
	.page-newabout .content {
        width:100%;
        padding-left:40px;
        padding-right:7%;
    }
}

@media only screen and (max-width: 700px) { 
	.page-newabout .content {
        align-items: flex-start;
        justify-content: flex-start;
        width:100%;
        height:100%;
        padding-left:50px;
        padding-top: 100px;
        top:auto;
        padding-bottom:40px;
        left:auto;
        transform: none;
        z-index: 10;
        overflow: scroll;
      -webkit-overflow-scrolling: touch;
        div {
            width: 91px;
            color:transparent;
        }
        h1 {
            font-size:36px;
        }
        p1 {
            text-align: left;
        }
    }
}


.fadeout {
    background: -webkit-linear-gradient(
        rgba(0,0,0,0) 0%,
        rgba(0,0,0, 1) 100%
    ); 
    background-image: -moz-linear-gradient(
        rgba(0,0,0, 0) 0%,
        rgba(0,0,0, 1) 100%
    );
    background-image: -o-linear-gradient(
        rgba(0,0,0, 0) 0%,
        rgba(0,0,0,1) 100%
    );
    background-image: linear-gradient(
        rgba(0,0,0, 0) 0%,
        rgba(0,0,0, 1) 100%
    );
    background-image: -ms-linear-gradient(
        rgba(0,0,0, 0) 0%,
        rgba(0,0,0, 1) 100%
    );

    position: absolute;
    bottom: 0px;
    height: 50px;
    width: 100%;
    z-index:100;
} 