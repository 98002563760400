.page-blogpost {

    width:  100%;
    height:  100%;
    
	position:relative;
	ul {
	  list-style: none;
	}

	
	.parallax-window {
		min-height: 400px;
		height:100%;
		background: transparent;
	}

	.parallax-mirror .parallax-slider img {
		width:100%;
		display:block;
	}

	.parallax-section-content {
		width:100%;
		height:100%;
		top:50%;
		left:50%;
		@include transform(translateX(-50%) translateY(-50%));	
		
	
	}


	
	/*Main Content Section*/
	.blog-section-main-content {

		// background-image: url('~/public/media/photography/SurfersDownHill.jpeg');
		background-position: center;
		background-size:cover;
		background-attachment: fixed;
		position:fixed;
		width:  100%;
		height:  100%;
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;



		.bg-video {
			position:absolute;
			width:100%;
			height:100%;
			top:0px;
			left:0px;
			overflow:hidden;
		}

		//Removing Plyr bottom controls
		.bg-video .plyr__controls {
			display:none !important;
		}

		#bg-video-loader {
			@include center();
			@include transform(translateX(-50%) translateY(-50%) scale(1.8));
		}

		.dark-overlay {
			z-index: 1;
			height: 500%;
			width: 500%;
			position: fixed;
			top: -30%;
			left: -30%;
			bottom: -30%;
			right: -30%;
			opacity: 0.0;
			background: rgba(0, 0, 0, 0.175);
			pointer-events: none;

			-webkit-transform: translate3d(0, 0, 0);
			-webkit-backface-visibility: hidden;
	    	-webkit-perspective: 1000;
			-webkit-transform: translateZ(0);
			-webkit-overflow-scrolling: touch;

		}

		.loader {
			@include center();
			width:50px;
			height:50px;
			z-index:10;
		}


		.story-holder {
			font-family:  'Raleway', sans-serif;
			position:  absolute;
			bottom:40px;
			right:50px;
			left:475px;
			top:40px;
			flex-direction:column;
			justify-content: flex-end;

			h1 {
				margin-right: 20px;
				white-space: nowrap;
				font-family: "Frank Ruhl Libre", sans-serif;
			}

			p1 {
				font-size: 15px;
				overflow: scroll;
				line-height:25px;
			}
		}

		/*Footer Section*/ 
		.information-footer {
			position: absolute;
			bottom:0px;
			width:100%;
			background-color: transparent;
			width:375px;
			left:50px;
			padding-top:  25px;
			padding-bottom:  25px;
			box-sizing:  border-box;
			border-top: solid 1px rgba(255,255,255,0.4);
			z-index: 1;

			.location-degrees  {
				font-size:16px;
			}

			.location-name {
				font-size:22px;
			}

			.subtitle {
				font-size:18px; 
			}

			.read-more {
				text-decoration: underline;
				font-style:italic;
				font-size: 15px;
			}
		
		}


		.overview {
			width:100%;
			height:100%;
		}

		/*Gallery*/ 
		.media-container {
			padding-bottom:100px;
			width: 1150px;
			margin:  auto;					
		}

		

		
		


		

		@media only screen and (max-width: 1170px) {
			
			.media-container {
				width:850px;
			}
		} 

		@media only screen and (max-width: 880px) {
			.story-holder {
				left: 40px;
				top:110px;
				justify-content:center;
				right:20px;
				bottom:120px;
			}
			.information-footer {
				left:40px;
				width:255px;
				// height:95px;
				.location-degrees  {
					font-size:14px;
				}
	
				.location-name {
					font-size:18px;
				}
	
				.subtitle {
					font-size:12px; 
				}
			}
			.media-container {
				width:80%;
			}
		} 



		/* shuffle widget */ 
		.shuffle-container {
			/* Bootstrap-style columns */
			.js-item {
				width:25%;
			}
			.column {
			  position: relative;
			  float: left;
			  min-height: 1px;
			  /*width: 25%;*/
			  padding-left: 4px;
			  padding-right: 4px;
			  
			  /* Space between tiles */
			  margin-top: 8px;
			}

			.col-span {
			  width: 50%;
			}

			.my-sizer-element {
			  width: 8.33333%;
			}

			/* default styles so shuffle doesn't have to set them (it will if they're missing) */
			.my-shuffle {
			  position: relative;
			  overflow: hidden;
			}

			/* Ensure images take up the same space when they load */
			/* https://vestride.github.io/Shuffle/images */
			.aspect {
			  position: relative;
			  width: 100%;
			  height: 0;
			  padding-bottom: 100%;
			  overflow: hidden;
			}

			.aspect__inner {
			  position: absolute;
			  top: 0;
			  right: 0;
			  bottom: 0;
			  left: 0;
			}



			img {
			  display: block;
			  width: 100%;
			  max-width: none;
			  height: 100%;
			  object-fit: cover;
			}

			/* Small reset */
			*,
			::before,
			::after {
			  box-sizing: border-box;
			}

			figure {
			  margin: 0;
			  padding: 5px;
			}
		}

		




		
	}


}

@media only screen and (max-width: 1030px) {
	.page-blogpost .blog-section-main-content .shuffle-container .js-item {
		width:33%;
	}
}


@media only screen and (max-width: 1000px) {
	.page-blogpost .blog-section-main-content .shuffle-container .js-item {
		width:33%;
	}
}

@media only screen and (max-width: 650px) {
	.page-blogpost .blog-section-main-content .shuffle-container .js-item {
		width:50%;
	}
}

@media only screen and (max-width: 500px) {
	.page-blogpost .blog-section-main-content .shuffle-container .js-item {
		width:100%;
	}
}


