.video-widget {
    width:100%;
    height:100%;
}
.plyr {
    height:100% !important;
}
.plyr__video-wrapper {
    height:100% !important;
    padding-bottom: 0 !important;
}

.plyr__video-wrapper .plyr__video-embed {
    padding-bottom: 0px !important;
    height: 100% !important;
}
.plyr--full-ui .plyr__video-embed > div {
    transform: none !important;
}

.plyr__control svg {
    margin:auto !important;
}

