.main-button-wrapper {
	width:46px;
	height:46px;
	border-radius: 23px;
}

.main-button-wrapper.video-button {
	width:108px;
	height:108px;
}


.main-button {
	transform-style: flat; 
	backface-visibility: hidden; 
	z-index: 999;
	width: 46px; 
	height: 46px; 
	border-radius: 23px; 
	cursor:pointer;
	pointer-events:  all;
	
	@include center(); 

}

.main-button-circle {
	width: 46px; 
	height: 46px; 
	border-radius: 23px; 
	position: absolute; 
	/*background-color: rgb(38,112,146);*/
	/*background: linear-gradient(-45deg, rgb(38, 112, 146) 0%, rgba(38, 112, 146, 0.5) 50%, rgba(38, 112, 146, 0.5) 51%);*/
	/* white to transparent linear-gradient(45deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 51%)*/
}


.main-button-arrows {
	position: absolute; 
	transform-style: flat; 
	backface-visibility: hidden; 
	width: 24px; 
	height: 11px; 
	pointer-events: none;
}


.arrow-1, .arrow-2 {
	position: absolute; 
	transform-style: flat; 
	backface-visibility: hidden; 
	background-color: rgb(255, 255, 255); 
	width: 15px; 
	height: 1px;
	@include transition(all, 0.2s, linear);
}

.main-button-mid-wrap {
	width: 78px;
	height: 78px;
	position: absolute; 
	transform-style: flat; 
	backface-visibility: hidden; 
	@include transform(translate3d(15px, 15px, 0px));
	width: 78px; 
	height: 78px; 
	border-radius: 39px; 
	
}

.main-button-large-wrap {
	position: absolute; 
	transform-style: flat; 
	backface-visibility: hidden; 
	@include transform(translate3d(0px, 0px, 0px));
	width: 100%; 
	height: 100%; 
	border-radius: 54px; 
	opacity: 0.4;
}


/*Video Button*/ 
.play-button {
	width:108px;
	height:108px;
	border-radius: 54px;
	position:absolute;
	top:50%;
	left:50%;
	@include transform(translateX(-50%) translateY(-50%));
	display:none;
}
.play-button-outer {
	width:108px;
	height:108px;
	border-radius:54px;
	background-color:rgba(235, 25, 60,0.4);
	position:absolute;
}
.play-button-mid {
	position:absolute;
	width:78px;
	background-color:rgba(235, 25, 60, 1.0);
	opacity:1.0;
	height:78px;
	border-radius:39px;
	@include transform(translate3d(15px, 15px, 0px));
}
.play-button-inner {
	width:50px;
	height: 50px;
}



/*Point Right*/
.main-button.pointRight .main-button-arrows{
	@include transform(translate3d(11px, 17px, 0px) rotate(90deg));
	.arrow-1 {
		@include transform(translate3d(-1px, 4px, 0px) rotate(-45deg));
	}

	.arrow-2 {
		@include transform(translate3d(10px, 4px, 0px) rotate(45deg));
	}
}

/*Point Right Click Effect*/
.main-button.pointRight {
	.arrow-1.rotate {
		-webkit-animation: mainButtonPointRightTopArrowAnimation 0.4s forwards ease-in-out;
		animation: mainButtonPointRightTopArrowAnimation 0.4s forwards ease-in-out;
	}
	.arrow-2.rotate {
		-webkit-animation: mainButtonPointRightBottomArrowAnimation 0.4s forwards ease-in-out;
		animation: mainButtonPointRightBottomArrowAnimation 0.4s forwards ease-in-out;
	}
  }
  
@keyframes mainButtonPointRightTopArrowAnimation {
	0% {
		@include transform(translate3d(-1px, 4px, 0px) rotate(-45deg));
	}
	100% {
		@include transform(translate3d(-1px, 4px, 0px) rotate(135deg));
	}
}
@keyframes mainButtonPointRightBottomArrowAnimation {
	0% {
		@include transform(translate3d(10px, 4px, 0px) rotate(45deg));
	}
	100% {
		@include transform(translate3d(10px, 4px, 0px) rotate(-135deg));
	}
}



/*Point Left*/
.main-button.pointLeft .main-button-arrows {
	@include transform(translate3d(17px, 9px, 0px) rotate(-90deg));
	.arrow-1 {
		@include transform(translate3d(1px, -4px, 0px) rotate(45deg));
	}

	.arrow-2 {
		@include transform(translate3d(-10px, -4px, 0px) rotate(-45deg));
	}
}

/*Point Right Click Effect*/
.main-button.pointLeft{
	.arrow-1.rotate {
		-webkit-animation: mainButtonPointLeftTopArrowAnimation 0.4s forwards ease-in-out;
		animation: mainButtonPointLeftTopArrowAnimation 0.4s forwards ease-in-out;
	}
	.arrow-2.rotate {
		-webkit-animation: mainButtonPointLeftBottomArrowAnimation 0.4s forwards ease-in-out;
		animation: mainButtonPointLeftBottomArrowAnimation 0.4s forwards ease-in-out;
	}
  }
  
@keyframes mainButtonPointLeftTopArrowAnimation {
	0% {
		@include transform(translate3d(1px, -4px, 0px) rotate(45deg));
	}
	100% {
		@include transform(translate3d(1px, -4px, 0px) rotate(-135deg));
	}
}
@keyframes mainButtonPointLeftBottomArrowAnimation {
	0% {
		@include transform(translate3d(-10px, -4px, 0px) rotate(-45deg));
	}
	100% {
		@include transform(translate3d(-10px, -4px, 0px) rotate(135deg));
	}
}

/*Point Down*/
.main-button.pointDown .main-button-arrows{
	@include transform(translate3d(11px, 18px, 0px) rotate(180deg));
	.arrow-1 {
		@include transform(translate3d(-1px, 4px, 0px) rotate(-45deg));
	}

	.arrow-2 {
		@include transform(translate3d(10px, 4px, 0px) rotate(45deg));
	}
}

.main-button.pointDown {
	&:hover {
		.arrow-1 {
			@include transform(translate3d(-2px, 4px, 0px) rotate(-25deg));
		}
		.arrow-2 {
			@include transform(translate3d(11px, 4px, 0px) rotate(25deg));
		}
		
	}
}

/*Point Up*/
.main-button.pointUp .main-button-arrows{
	transform: translate3d(11px, 17px, 0px) rotate(-180deg);
	.arrow-1 {
		@include transform(translate3d(-1px, 4px, 0px) rotate(45deg));
	}

	.arrow-2 {
		@include transform(translate3d(9px, 4px, 0px) rotate(-45deg));
	}
}

.main-button.pointUp {
	&:hover {
		.arrow-1 {
			@include transform(translate3d(-2px, 4px, 0px) rotate(25deg));
		}
		.arrow-2 {
			@include transform(translate3d(11px, 4px, 0px) rotate(-25deg));
		}
		
	}
}

.center {
	position:absolute;
	top:50%;
	left:50%;
	@include transform(translateX(-50%) translateY(-50%));
}

.topRight {
	top:15px;
	right:20px;
}




/*Default Hover Events For Mobile*/
@media only screen and (max-width: 545px) { 
	.main-button.pointRight:hover {
        .arrow-1 {
			@include transform(translate3d(-1px, 4px, 0px) rotate(-45deg));
		}

		.arrow-2 {
			@include transform(translate3d(10px, 4px, 0px) rotate(45deg));
		}
    }
	.main-button.pointDown:hover {
        .arrow-1 {
			@include transform(translate3d(-1px, 4px, 0px) rotate(-45deg));
		}

		.arrow-2 {
			@include transform(translate3d(10px, 4px, 0px) rotate(45deg));
		}
    }
    .main-button.pointUp:hover {
        .arrow-1 {
			@include transform(translate3d(-1px, 4px, 0px) rotate(45deg));
		}
		.arrow-2 {
			@include transform(translate3d(10px, 4px, 0px) rotate(-45deg));
		}
    }
}