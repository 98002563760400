.blog-section-video {

	height:100%;
	
	.main-button-wrapper {
		@include center(); 
	}



}

