.navigation {
	overflow:hidden;

	/*Background image component*/
	.iron-image-loaded {
		background-position:center;
	}

}

.page-navigation {
	background-color: #35383F;
	width:100%;
	height:100%;
	@include transition(background, 0.2s, ease-in);
	-webkit-backface-visibility: none;
		
}




.page-navigation .overlay {
	position:absolute;
	top:20px;
	left:20px;
	right:20px;
	bottom:20px;
	background-color: transparent;
	box-shadow: rgba(0, 0, 0, 0.43) 2px 4px 12px 2px;
	
}

.page-navigation .left-section {
	position:absolute;
	width:50%;
	height:100%;
	left:20px;
	top:0px;
}

.page-navigation .left-section .inner {
	position:absolute;
	display:block;
	width:100%;
	height:auto;
	top:0px;
	left:0px;
	right:0px;
	bottom:0px;
	margin: 20px 0px 20px 20px;
}
.page-navigation .left-section .inner .menu-board {
	position:absolute;
	width:100%;
	height:100%;
	left:-20px;
}

.loading .page-navigation {
	.title, .nav-menu {
		display:none;
	}
}

.page-navigation .left-section .inner .menu-board .title {
	position:absolute;
	left:52%;
	font-size: 14px;
	top:35px;
	color: $navigation-inactive-text-color;

	h1 {
		font-size:13px;
	}

	div {
		width:45px;
		height:1px;
		top:8px;
		right:-49px;
		background-color:white;
		position:absolute;
	}
}

.page-navigation .left-section .inner .menu-board .loading-progress {
	position:absolute;
	z-index:10;
	left:52%;
	right:0px;
	width:auto;
	margin: 0px 40px 0px 0px;
	bottom:123px;
	top:152px;
	color: #0BB494;
	span {
		font-size:28px;
		color: #0BB494;
	}
}

.page-navigation .left-section .inner .menu-board .nav-menu-holder {
	position:absolute;
	left:52%;
	right:0px;
	width:auto;
	margin: 0px 40px 0px 0px;
	display:block;
	bottom:123px;
	top:152px;

	.upbrush {

		display:none; /*temporary*/
		position:absolute;
		top:0px;
		left:0px;
		width:250px;
		left:-40px;
		top:-5px;
	}

	.nav-menu {
		position:relative;
		font-size:30px;
	}

	.nav-item {
		@include transition(all, 0.2s, ease-in-out);
		position:relative;
		clear:both;
		display:block;
		white-space:nowrap;
		color: white; /*temporary*/
		left:0px;
		cursor:pointer;
		line-height:45px;
		backround-color:transparent;
		/*font-family: 'DryBrush', $primary-font-family;*/
	}

	.nav-item.active {
		color: $navigation-active-text-color;
		background-color: transparent !important;
	}
} 

.page-navigation .left-section .inner .menu-board .logo {
	position:absolute;
	transform: translate3d(20px, 158px, 0px);
	width:184px;
	height:24px;
}


.page-navigation .left-section .inner .menu-board .email {
	left:20px;
	font-size:15px;
	bottom:20px;
	position:absolute;
	color:white;
}

.page-navigation .left-section .inner .menu-board .copyright {
	position:absolute;
	left:52%;
	bottom:20px;
	color: white;
	font-size:15px;
}




/*Mobile Portrait*/
@media only screen and (max-width: 545px) { 
	.page-navigation .left-section .inner .menu-board .nav-menu-holder {
		left:60px;
		top:122px;
		.upbrush {
			width:220px;
			left:-40px;
			top:-5px;
		}
		.nav-menu {
			font-size:22px;

			.nav-item {
				line-height:40px;
			}
		}
	}
	.page-navigation .left-section .inner .menu-board .title {
		display:none;
	}
	.page-navigation .left-section .inner .menu-board .email {
		left:60px;
	}
	.page-navigation .left-section .inner .menu-board .copyright {
		display:none;
	}
}


/*Mobile Landscape*/
@media screen and (min-width: 545px) and (max-height: 300px) {
	.page-navigation .left-section .inner .menu-board .nav-menu-holder {
		left:52%;
		top:30vh;

		.upbrush {
			width:150px;
			left:-30px;
		}

		.nav-menu {
			font-size:16px;
			.nav-item {
				line-height:25px;
			}
		}
	}

	.page-navigation .left-section .inner .menu-board .email {
		left:30px;
		bottom:45px;
		font-size:12px;
	}
	.page-navigation .left-section .inner .menu-board .copyright {
		left:30px;
		bottom:20px;
		font-size:12px;
	}
}



/* ANIMATIONS */ 

@-webkit-keyframes parallax-item-animation {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg);
		-moz-transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg); 
	}
	  100% {
		-webkit-transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg);
		-moz-transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg);
		transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg); 
	} 
}
@-ms-keyframes parallax-item-animation {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg);
		-moz-transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg); }
	  100% {
		-webkit-transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg);
		-moz-transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg);
		transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg); 
	} 
}
@-moz-keyframes parallax-item-animation {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg);
		-moz-transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg); }
	  100% {
		-webkit-transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg);
		-moz-transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg);
		transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg); 
	} 
}
@keyframes parallax-item-animation {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg);
		-moz-transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0,-2%,0) rotateZ(0deg); }
	  100% {
		-webkit-transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg);
		-moz-transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg);
		transform: rotateZ(10deg) translate3d(0,0,0) rotateZ(-10deg); 
	} 
}

@-webkit-keyframes parallax-background-animation {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg);
		-moz-transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg); }
	  100% {
		-webkit-transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg);
		-moz-transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg);
		transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg); 
	} 
}
@-ms-keyframes parallax-background-animation {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg);
		-moz-transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg); }
	  100% {
		-webkit-transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg);
		-moz-transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg);
		transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg); 
	} 
}
@-moz-keyframes parallax-background-animation {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg);
		-moz-transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg); }
	  100% {
		-webkit-transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg);
		-moz-transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg);
		transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg); 
	} 
}
@keyframes parallax-background-animation {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg);
		-moz-transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0,-1%,0) rotateZ(0deg); }
	  100% {
		-webkit-transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg);
		-moz-transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg);
		transform: rotateZ(10deg) translate3d(0,1%,0) rotateZ(-10deg); 
	} 
}



