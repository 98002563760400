.cd-section {
  min-height: 100%;
  position: relative;
  // height:500px;
  // height:1000px;
  height:100%;
  overflow:hidden;
}
// .cd-section:nth-of-type(odd) {
//   //background-color: #3e3947;
// }
// .cd-section:nth-of-type(odd) p {
//   color: #898099;
// }
// .cd-section:nth-of-type(even) {
//   //background-color: #745360;
// }
// .cd-section:nth-of-type(even) p {
//   color: #bda3ad;
// }
// .cd-section h1, .cd-section p {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   bottom: auto;
//   right: auto;
//   -webkit-transform: translateX(-50%) translateY(-50%);
//   -moz-transform: translateX(-50%) translateY(-50%);
//   -ms-transform: translateX(-50%) translateY(-50%);
//   -o-transform: translateX(-50%) translateY(-50%);
//   transform: translateX(-50%) translateY(-50%);
//   width: 90%;
//   max-width: 768px;
//   text-align: center;
// }
// .cd-section h1 {
//   color: white;
//   font-weight: 300;
//   text-transform: uppercase;
//   font-size: 20px;
//   font-size: 1.25rem;
// }
// .cd-section p {
//   line-height: 1.6;
// }
@media only screen and (min-width: 768px) {
  // .cd-section h1 {
  //   font-size: 30px;
  //   font-size: 1.875rem;
  // }
  // .cd-section p {
  //   font-size: 20px;
  //   font-size: 1.25rem;
  //   line-height: 2;
  // }
}

.cd-scroll-down {
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  width: 38px;
  height: 44px;
  
}

/* No Touch devices */
.cd-nav-trigger {
  display: none;
}

.no-touch #cd-vertical-nav {
  z-index: 100;
  position: fixed;
  right: 40px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.no-touch #cd-vertical-nav li {
  text-align: right;
  width:400px;
  height:50px;
}
.no-touch #cd-vertical-nav a {
  /* prevent weird movements on hover when you use a CSS3 transformation - webkit browsers */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position:absolute;
  transform: translate3d(-160px, 11px, 0px);
}
.no-touch #cd-vertical-nav a:after {
  content: "";
  display: table;
  clear: both;
}
.no-touch #cd-vertical-nav a span {
  float: right;
  display: inline-block;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
}

.no-touch #cd-vertical-nav a.is-selected span {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.no-touch #cd-vertical-nav a:hover .cd-label {
  opacity: 1; 
}
.no-touch #cd-vertical-nav a > div, {
  @include transition(all,0.3s,ease);
}

/*Pop out side nav bar item*/
.no-touch #cd-vertical-nav a.is-selected > div {
  @include transform(translateX(-30%));
}

/*Fade in side nav bar label*/
.no-touch #cd-vertical-nav a .cd-label {
  @include transition(all,0.2s,ease);
}
.no-touch #cd-vertical-nav a.is-selected .cd-label {
  transition-delay: 0.2s;
}

/*Increase side nav bar dot size*/
.no-touch #cd-vertical-nav a .cd-dot {
 @include transition(all,0.3s,ease-in);
 transition-delay: 0.1s;
}

.no-touch #cd-vertical-nav a.is-selected .cd-label {
  opacity: 1;
}
.no-touch #cd-vertical-nav a.is-selected .cd-dot {
  background-color: white;
  width:12px;
  height:12px;
}
.no-touch #cd-vertical-nav a.is-selected .cd-line {
  @include transform(translate3d(13px, 22px, 0px) rotate(0deg));
}
.no-touch #cd-vertical-nav .cd-dot {
  display:inline-block;
  vertical-align:middle;
  margin-left:20px;
  /* we set a top value in order to align the dot with the label. If you change label's font, you may need to change this top value*/
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #d88683;  
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.no-touch #cd-vertical-nav .cd-label {
  display:inline-block;
  vertical-align:middle;
  color: white;
  font-size: 18px;
  opacity: 0;
  opacity: 1; 
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.no-touch #cd-vertical-nav .cd-line {
  width:2px;
  height:20px;
  background-color:gray;
  color:gray;
  position:absolute;
  @include transform(translate3d(106px,-10px,20px));
}

/* Touch devices */
.touch .cd-nav-trigger {
  display: block;
  z-index: 2;
  position: fixed;
  bottom: 30px;
  right: 5%;
  height: 44px;
  width: 44px;
  border-radius: 0.25em;
  background: rgba(255, 255, 255, 0.9);
}
.touch .cd-nav-trigger span {
  position: absolute;
  height: 4px;
  width: 4px;
  background-color: #3e3947;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.touch .cd-nav-trigger span::before, .touch .cd-nav-trigger span::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
}
.touch .cd-nav-trigger span::before {
  top: -9px;
}
.touch .cd-nav-trigger span::after {
  bottom: -9px;
}

.touch #cd-vertical-nav {
  position: sticky;
  z-index: 1;
  right: 5%;
  bottom: 30px;
  width: 90%;
  max-width: 400px;
  max-height: 90%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-transform-origin: right bottom;
  -moz-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  -o-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  transition-duration: 0.2s;
  border-radius: 0.25em;
  background-color: rgba(255, 255, 255, 0.9);
}
.touch #cd-vertical-nav a {
  display: block;
  padding: 1em;
  border-bottom: 1px solid rgba(62, 57, 71, 0.1);
}
.touch #cd-vertical-nav a span:first-child {
  display: none;
}
.touch #cd-vertical-nav a.is-selected span:last-child {
  color: #d88683;
}
.touch #cd-vertical-nav.open {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.touch #cd-vertical-nav.open + .cd-nav-trigger {
  background-color: transparent;
}
.touch #cd-vertical-nav.open + .cd-nav-trigger span {
  background-color: rgba(62, 57, 71, 0);
}
.touch #cd-vertical-nav.open + .cd-nav-trigger span::before, .touch #cd-vertical-nav.open + .cd-nav-trigger span::after {
  background-color: #3e3947;
  height: 3px;
  width: 20px;
  border-radius: 0;
  left: -8px;
}
.touch #cd-vertical-nav.open + .cd-nav-trigger span::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
}
.touch #cd-vertical-nav.open + .cd-nav-trigger span::after {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  bottom: 0;
}
.touch #cd-vertical-nav li:last-child a {
  border-bottom: none;
}

@media only screen and (min-width: 768px) {
  .touch .cd-nav-trigger, .touch #cd-vertical-nav {
    bottom: 40px;
  }
}




#nav-line-container { 
  position:absolute;
}



