.section-gallery {

	

}

.section-gallery {
	.controls {
		position:absolute;
		right:34px;
		top:50%;
		transform: translateY(-50%);

		.main-button-wrapper {
			margin:10px;
		}
	}
}