

@font-face {
  font-family: 'DryBrush';
  src: url('~/public/media/fonts/dry_brush/DryBrush.ttf') format('truetype');
}

@font-face {
  font-family: 'LeviBrush';
  src: url('~/public/media/fonts/levi_brush/LEVIBRUSH.TTF') format('truetype');
}

@font-face {
  font-family: 'DaStreets';
  src: url('~/public/media/fonts/da_streets/DaStreets.ttf') format('truetype');
}


