html {
	width:100%;
	height:100%;
    touch-action: manipulation;
}

html, html a {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}
body {
	// background: $primary-background-color;
    font-family: $primary-font-family;
    font-weight: $primary-font-weight;
    color:$primary-color;
    width:100%;
    height:100%;
    margin:0px;

}

body::after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
}

h,h1,h2,p,p1,p2 {
    color:$primary-color;
    margin:0px;
}
input {
    border:none;
    background-color:white;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
a,Link{
    text-decoration:none;
}

figure {
    margin:0px;
}

#app{
	//width:100%;
	//height:100%;
   //overflow:scroll;
}

#Home {
	width:100%;
	height:100%;

    .loading-overlay {
        width:100%;
        height:100%;
        position:absolute;
        z-index:100000;
        img {
            width: 50px;
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translateX(-50%) translateY(-50%));
        }
    }
}
@media (max-width: 600px) {
  #Home {
  	background-position: -70px 0px;
  }
}


button {
	background-color:transparent;
	border:none;
	color:$primary-color;
	cursor:pointer;
}

button:focus {
	outline:0;
}

li {
	border:none;
}


#parallax-window {
    height: 100%;
    background: transparent;
    position:relative;
}

.overlay {
    position:absolute;
	top:20px;
	left:20px;
	right:20px;
	bottom:20px;
	background-color: transparent;
	box-shadow: rgba(0, 0, 0, 0.43) 2px 4px 12px 2px;
	border-radius:5px;
}

