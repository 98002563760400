

.page-subjectlanding, .page-discographyList {
    background-color: #717171;
    width:100%;
    height:100%;
    .subjectlanding-button-container {
        width: 291px;
        height:400px;
        position: absolute;
        left: 50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .subjectlanding-button {
        width: 100%;
        height:40px;
        border: solid 1px white;
        border-radius:1px;
        color: white;
        line-height:40px;
        font-size:18px;
        text-align: center;
    }
    .dark-overlay {
        position: absolute;
        width:100%;
        height:100%;
        background-color:black;
        opacity:0.5;
    }

    .bottom-email {
        z-index:100;
        left:20px;
        font-size:15px;
        bottom:20px;
        position:absolute;
        color:white;
    }

    .bottom-copyright {
        z-index:100;
        position:absolute;
        right:20px;
        bottom:20px;
        color: white;
        font-size:15px;
        text-align: center;
    }
}




/*Mobile Portrait*/
@media only screen and (max-width: 545px) { 
    .page-subjectlanding, .page-discographyList {
        .bottom-email {
            left: 50%;
            transform: translateX(-50%);
            
        }
        .bottom-copyright {
            // left: 50%;
            // transform: translateX(-50%);
            // right:0px;
            display: none;
        }
        .social-widget {
            display: none;
        }
    }
}


/*Mobile Landscape*/
@media screen and (min-width: 545px) and (max-height: 300px) {
	.bottom-email {
		left:30px;
		bottom:45px;
		font-size:12px;
	}
	.bottom-copyright {
		right:30px;
		bottom:20px;
		font-size:12px;
	}
}

