.projects {
	overflow-y:scroll;
	-webkit-overflow-scrolling: touch;

	background-color:#54577C;
}

.projects-footer {
	text-align:center;
	font-size:50px;
	font-family: 'DryBrush', $primary-font-family;
}
.page-projectswrapper {
	display:flex;
	flex-wrap:wrap;
	overflow-x:hidden;
	overflow-y: scroll;
	max-width:100%;

	

	.cd-background-wrapper {
		height:100%;
		background-color:white;
	}
}

.project-item {
	width:33.33%;
	min-width:400px;
	min-height:200px;
	display:inline-block;
	flex-grow:1;
	position: relative;
	max-width:33.33%;
}

.project-item .loading-overlay {
	width:100%;
	height:100%;
	position:absolute;
	top:0px;
	left:0px;
	z-index:100;

	img {
		width:50px;
		position:absolute;
		left:50%;
		top:50%;
		@include transform(translateX(-50%) translateY(-50%));
	}
}

/*mix-match loading background colors*/
.project-item:nth-of-type(odd)  .cd-background-wrapper {
	background-color:rgb(186, 193, 186);
}
.project-item:nth-of-type(even) .cd-background-wrapper {
	background-color:rgb(174, 182, 175);
}



.project-item-hovermenu {
	position:absolute;
	top:0px;
	left:0px;	
	width:102%;
	height:100%;
	box-sizing:border-box;
	background-clip:content-box;
	&:hover {
		.project-item-hovermenu-brief, .project-item-hovermenu-button {
			opacity:1.0;
		}
		.project-item-hovermenu-button {
			@include rotate(180deg);
		}
	}

	.project-item-hovermenu-brief {
		width:80%;
		position:absolute;
		bottom:90px;
		left:40px;
		opacity:0.0;
		h1 {
			font-size:24px;
		}
		span {
			font-size:21px;
		}
	}

	.project-item-hovermenu-button {	
		@include hoverBoard;
		position:absolute;
		border-radius:50%;
		width:35px;
		height:35px;
		bottom:40px;
		left:40px;
		background-color:white;
		opacity:0.0;

		img {
			width:100%
		}

	}
}

.projects-end-block {	
	border: solid 20px rgb(186,195,187);
	background-color: rgb(175, 182, 176);
	min-height:200px;
	box-sizing:border-box;
	display:inline-block;
	flex-grow:1;
	position: relative;
	height:auto;

	.inner-content {
		margin-left:68%;
		width:100%;
		height:100%;
	}
	p1 {
		position:absolute;
		top:10px;
		font-size:14px;
	}

	div {
		position:absolute;
		bottom:5px;
		span {
			font-size:18px;
		}
	}
}

.project-item-hovermenu, .project-item-hovermenu-button, .project-item-hovermenu-brief {
	@include transition(all,0.2s, ease-in-out);
}



@media only screen and (max-width: 1200px) { 
	.project-item {
		max-width:50%;
		width:50%;
	}

	/*mix-match loading background colors*/
	.project-item:nth-child(4n+1) .cd-background-wrapper, .project-item:nth-child(4n+4) .cd-background-wrapper {
		background-color:rgb(186, 193, 186);
	}
	.project-item:nth-child(4n+3) .cd-background-wrapper, .project-item:nth-child(4n+2) .cd-background-wrapper {
		background-color:rgb(174, 182, 175);
	}

	.projects-end-block {
		height:250px;
		.inner-content {
			margin-left:50%;
		}
	}
}
@media only screen and (max-width: 800px) { 
	
	.project-item {
		max-width:100%;
	}

	/*mix-match loading background colors*/
	.project-item:nth-of-type(odd)  .cd-background-wrapper {
		background-color:rgb(186, 193, 186);
	}
	.project-item:nth-of-type(even) .cd-background-wrapper {
		background-color:rgb(174, 182, 175);
	}

	

	.projects-end-block {
		width:100%;
	}

	.projects-end-block {
		p1 {
			font-size:12px;
		}
		div span{
			font-size:14px;
		}
	}
}

@media only screen and (max-width: 545px) { 
	.project-item {
		width:100%;
	}

	.project-item:nth-of-type(odd)  .cd-background-wrapper {
		background-color:rgb(186, 193, 186);
	}
	.project-item:nth-of-type(even) .cd-background-wrapper {
		background-color:rgb(174, 182, 175);
	}
	
	.project-item-hovermenu-button {
		opacity:1.0 !important;
	}
	.projects-end-block {
		.inner-content {
			margin-left:20px;
		}
	}
}

/*Default Hover Events For Mobile*/
@media only screen and (max-width: 545px) { 
	.project-item-hovermenu:hover {
		opacity:1.0 !important;
		.project-item-hovermenu-brief{
			opacity:1.0 !important;
		}
	}
}



.project-item-mobile-button {
	position:absolute;
	bottom:40px;
	left:50px;
	width:30px;
	height:30px;
	border-radius:50%;
	background-color:white;
	z-index:1;

	img {
		width:  100%;
	}
}