.blog-list {
	overflow:auto;

	.iron-image-loaded {
		background-position:center;
	}

	.explore-header {
		position:absolute;
		top:27px;
		left:50%;
		transform:translateX(-50%);


		font-family:'Roboto', sans-serif;
		font-weight:200;
		font-size:65px;
	}
	.searchbar-widget {		
		z-index: 100;

		position:absolute;
		top:36px;

		width:100%;
	

		box-sizing: border-box;
		padding-left:210px;
		padding-right:210px;


		-webkit-transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
	    -webkit-perspective: 1000;

	    -webkit-transform: translateZ(0);

	    -webkit-overflow-scrolling: touch;
		

		.inner {
			
			width:100%;
			height: auto;
			
			cursor: pointer;

			


			.search-section {
				@include transition(all, 0.15s, linear);

				width:100%;
				height:55px;

				display:flex;
				flex-direction:row;
				justify-content: center;

				box-sizing:border-box;
				padding-left:15px;
				padding-right:15px;

				border-radius: 5px;

				background-color: rgba(255,255,255,0.3);

				img {
					width:20px;
				}
				input {
					width:170px;
					height:100%;

					margin-left:15px;
					padding: 0px;

					font-size:12px;

					color:white;
					background-color: transparent;
				}

				input::placeholder {
					color:white;
					white-space:nowrap;
				}

				&:hover {

					@include transform(scale(1.01));
					background-color: rgba(255,255,255,0.5);

					box-shadow:5px 5px 25px 0px rgba(46,61,73,0.7);
				}
			}
		}
	}


	.blockarrow-widget {
	 	position: fixed;
	 	top: 64px;
    	right:40px;
	 	@include transform(translate(-50%,-50%) rotate(45deg));
	 	
	 	

	 	svg {
		    position: absolute;
		    transform: scale(0.75) rotate(-45deg);
		}

	 	&:hover {
	 		@include transform(translate(-50%, -50%) rotate(45deg) scale(1.2));
	 		background-color:white;
			path {
				fill:#54577C;
			}
	 	}
	 	
	}
}

.page-bloglist {
    width: 100%;
    height:100%;

   	overflow:hidden;

	background-color: #54577C;
	

    

	.shuffle-wrapper {
		position: absolute;
		top:0px;
		width:100%;
		height:100%;
		
		box-sizing:border-box;
		padding-top:140px;
		padding-bottom:0px;
		padding-left:200px;
		padding-right:200px;
		
		-webkit-overflow-scrolling: touch;

		.shuffle-container.shuffle {
			overflow:visible !important;
		}
		
		.picture-item {
			height:auto;
			cursor:pointer;
			margin-top:10px;
			box-sizing:border-box;
			padding:10px;
			/*border-radius: 5px;*/
			z-index: 100000;

			&:hover {
				z-index: 10000000;
			}
			.aspect {
				/*border-radius:5px;*/
				
				@include transition(all, 0.25s, linear);

				width:100%;
				height:100%;
				opacity:1.0;

				&:hover {
					box-shadow:5px 5px 25px 0px rgba(46,61,73,0.7);


					
					.picture-item-bg {
						@include grayscale(0%);
						opacity:1.0;
		    			.overlay {
		    				background-color:rgba(0,0,0,0.2);
		    			}

					}
				}

				

				.picture-item-bg {
				 	position:relative;

				 	@include transition(all, 0.55s, linear);
				 	@include grayscale(30%);

			    	width:100%;
			    	height: 0;

			    	padding-top: 66.64%;
			    	
			  	  	/*border-radius: 5px;*/

			    	background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					
					opacity:0.95;		


					.overlay {
						@include transition(all, 0.25s, linear);

						position:absolute;
						width:100%;
						height:100%;
						top:0px;
						left:0px;

						/*border-radius:5px;*/

						background-color:rgba(0,0,0,0.38);
						

						h1 {

							@include transition(all, 0.25s, linear);

							position: absolute;
							width:100%;
							bottom: 20px;
							
							box-sizing:border-box;
							padding:0px 10px;

							font-style: italic;
							font-size:25px;
							//font-family: 'Roboto',sans-serif;
							font-weight: 100;
						}

						.type-icon {
							width: 30px;
						    position: absolute;
						    right: 20px;
						    top: 20px;
						    opacity:0.8;
						}
					}    		
			    }
				.aspect__inner {
					position: relative;
				    overflow: hidden;



				    .picture-item-details {
				    	position: absolute;
					    background-color: #00191a;
					    width: 100%;
					    height: 50px;
					    bottom: 0px;
					    opacity: 0.7;
					}
				}
			}
		}

		.sizer {
			
			width:8.333%;
			position:absolute;
			visibility:hidden;
			
		}
	}
}


@media only screen and (max-width: 1110px) { 
	.page-bloglist {
		.shuffle-wrapper {
			padding-left: 100px;
			padding-right: 100px;
			.picture-item .aspect .picture-item-bg .overlay h1{
				
			}
		}
	}
}

@media only screen and (max-width: 670px) { 

	.blog-list {
		.explore-header {
			top:36px;
			font-size: 45px;
		}
		.searchbar-widget {
			padding-left:40px;
			padding-right:40px;

			.inner {
				.search-section {
					padding-left:25px;
					img {
						width:15px;
					}
					input {
						font-size: 10px;
					}
				}
			}

			
		}
	}

	.page-bloglist {
		.shuffle-wrapper {
			padding-left:30px;
			padding-right:30px;

			.picture-item .aspect .picture-item-bg .overlay h1{
				bottom:10px;
				font-size:16px;
			}
			.picture-item .aspect .picture-item-bg .overlay .type-icon{
				top:10px;
				right:10px;

				width:20px;
			}
		}
	}
}
