.tiny {
	width:33.333%;
	height:220px;
}

.long {
	width:66.66667%;
	height:220px;
}

.bulk {
	height:464px;
	width:66.66667%;
}

.tall {
	width:33.333%;
	height:464px;
}


@media only screen and (max-width: 900px) { 
	.tiny {
		width:50%;
	}
	.long {
		width:100%;
	}
	.bulk {
		width:100%;
	}
	.tall {
		width:50%;
	}
}

