@mixin center() {
	position:absolute;
	top:50%;
	left:50%;
	transform:translateX(-50%) translateY(-50%);
}

@mixin centerHorizontally() {
	position:absolute;
	left:50%;
	transform:translateX(-50%);
}






@mixin hoverBoard() {
	box-shadow:5px 5px 25px 0px rgba(46,61,73,0.2);
	&:hover {
		box-shadow: 2px 4px 8px 0px rgba(46,61,73,0.2)
	}
}

@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin transform($value) {
	-webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;
    -o-transform: $value;
    transform: $value;
}

@mixin transition($target, $time, $type) {
	webkit-transition: $target $time ease-in;
    -moz-transition: $target $time ease-in;
    -o-transition: $target $time ease-in;
    -ms-transition: $target $time ease-in;
    transition: $target $time ease-in;
}

@mixin grayscale ($percentage) {
	-webkit-filter: grayscale($percentage);
	-moz-filter: grayscale($percentage);
	-o-filter: grayscale($percentage);
	-ms-filter: grayscale($percentage);
	filter: grayscale($percentage);
}

@mixin blur ($amount) {
	-webkit-filter: blur($amount);
	-moz-filter: blur($amount);
	-o-filter: blur($amount);
	-ms-filter: blur($amount);
	filter: blur($amount);
}


@mixin background($url, $position, $size) {
	background-image: url($url);
	background-position: $position;
	background-size: $size;
	background-repeat: no-repeat;
}

@mixin parallaxBackground () {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;	
	
	width:105%;
    height:105%;
    top:-2%;
    left:-2%;
	position: absolute;	
	-webkit-animation: parallax-background-animation 2s 0.35s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -moz-animation: parallax-background-animation 2s 0.35s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
	animation: parallax-background-animation 2s 0.35s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
}


@mixin parallaxItem () {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;	
    width:105%;
    height:108%;
    top:-2%;
    left:-2%;
	position: absolute;	
	-webkit-animation: parallax-item-animation 2s 0.35s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -moz-animation: parallax-item-animation 2s 0.35s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
	animation: parallax-item-animation 2s 0.35s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
