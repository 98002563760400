.page-homepage {
	width: 100%;
    height: 100%;
	background-color: #35383F;
	
}
.page-homepage .main-button-wrapper {
	display:none;
}


.page-homepage .overlay {
	

	.homepage-header {
		font-family: 'DaStreets', $primary-font-family;
	}

	.reveal-under {
		position:absolute;
		left:50%;
		transform:translateX(-50%);

		bottom:20px;
		display:flex;
		flex-direction:column;
		align-items:center;
		
		cursor:pointer;
		
		h1 {
			float:left;
			font-size:20px;
			font-weight:200;
			margin-bottom:15px;
		}

		.blockarrow-widget {
			@include transform(rotate(45deg));
		 	svg {
			    position: absolute;
			    @include transform(scale(0.75) rotate(135deg));
			}
			
		 	&:hover {
		 		background-color:white;
				path {
					fill:#54577C;
				}
		 	}
	 	}

		@include transition(all,0.1s,ease);
	}


	.reveal-over {
		position:absolute;
		
		left:50%;
		top:20px;
		transform:translateX(-50%);

		
		display:flex;
		flex-direction:column;
		align-items:center;
			


		cursor:pointer;
		h1 {
			float:left;
			font-size:20px;
			font-weight:200;
			margin-top:15px;
		}

		.blockarrow-widget {
			@include transform(rotate(225deg));
		 	svg {
			    position: absolute;
			    @include transform(scale(0.75) rotate(135deg));
			}
			
		 	&:hover {
		 		background-color:white;
				path {
					fill:#54577C;
				}
		 	}
	 	}

		@include transition(all,0.1s,ease);
	}
}



.page-homepage .subscribe {
	display:none;
	@include transition(all,0.2s,ease);
	position:absolute;	
	bottom:10px;
	width:250px;
	height:70px;
	text-align:center;
	/*cursor:pointer;*/
	font-family: 'DryBrush', $primary-font-family;
	h1,img {
		width:100%;
		height:100%;
		position:absolute;
		top:0px;
		left:0px;
	}
 

	h1 {
		font-size:16px;
		line-height:78px;
		color: #55a5d4;
		z-index:10;
	}

	&:hover {
		@include transform(scale(1.1));
	}
	right:15px;
}

.page-homepage .email {
	position:absolute;
	bottom:15px;
	left:10px;
	height:40px;
}

.page-homepage .email input:nth-child(1) {
	height:100%;
	width:240px;
	background-color: hsla(199,49%,42%,0.8);
}



@media only screen and (max-width: 545px) { 
	.page-homepage {
		@include background('~/public/media/photography/peru-surf-compressor.jpg', 60%, cover);

	}
	

	.page-homepage .subscribe {
		width:75%;
		left:0px;
		right:0px;
		margin:auto;
	}
}