.social-widget {
	@include transition(all,0.5s, ease);

	.main-button-wrapper {
		position:absolute;
		right: 20px;
    	top: 18px;

	}

	.social {
		position:absolute;
		top:30px;
		right:20px;
		z-index:10;

		img {
			width: 20px;
			padding:0px 10px;
			box-sizing: initial !important;
			cursor:pointer;
			@include transition(all,0.1s, ease);
			float:left;
			background-repeat: no-repeat;
			background-size:contain;
			&:hover {
				@include transform(scale(1.5));
			}
		}
	}


	.main-button {
		display:none;
	}
}



@media only screen and (max-width: 745px) { 

	.social-widget {
		.social {
			@include transition(all, 0.2s, linear);
			display:flex;
			flex-direction:column;
			height:0px;
			top:70px;
			right:32.7px;

			img {
				padding:0px;
				height:20px;
				
				margin-bottom:20px;
			}
		}

		.main-button {
			display:initial;
		}
	}

	.social-widget {
		.social img.active {
			@include transform(scale(1.0))
		}
		.social img.inactive {
			@include transform(scale(0.0))
		}

	}

	.social-widget {
		.social img {
			&:hover {
				@include transform(scale(1.0));
			}
		}
	}

}






