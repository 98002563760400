.loading .navigation-button {
	opacity: 0.0;
}
.navigation-button div {
  width:20px;
}
.back-button div {
  width: 15px;
}

.navigation-button-holder {
    .navigation-button div, .back-button div{
      height:1px;
      background-color:white;
      @include transition(all, 0.5s, ease-in-out);
      position:absolute;
      pointer-events:none;
    }

    .navigation-button {
      z-index: 1000;
      position:fixed;
      width:46px;
      height:46px;
      top:40px;
      left:40px;
      float:left;
      border-radius:23px;
      background-color: transparent;
      @include transition(all, 0.2s, ease-in-out);
      
      div:nth-child(1) {
       @include transform(translate3d(13px, 18px, 0px));
      }
      div:nth-child(2) {
        @include transform(translate3d(13px, 22px, 0px));
      }
      div:nth-child(3) {
        @include transform(translate3d(13px, 26px, 0px));
      }
    }

    .back-button {
      position: fixed;
      z-index: 999;
      top:40px;
      left:40px;
      width:46px;
      height:46px;
      opacity:0.0;
      border-radius:23px;
    }

    .back-button div:nth-child(1) {
      @include transform(translate3d(13px, 22px, 0px));
    }

    .back-button div:nth-child(2) {
      @include transform(translate3d(13px, 22px, 0px));
    }
}






.navigationButtonTopImageAnimation {
  -webkit-animation: navigationButtonTopImageAnimation 0.5s forwards ease-in-out;
  animation: navigationButtonTopImageAnimation 0.5s forwards ease-in-out;
}

@keyframes navigationButtonTopImageAnimation {
  0% {
    @include transform(translate3d(13px, 18px, 0px));
  }

  50% {
    @include transform(translate3d(13px, 9px, 0px));
  }

  100% {
    @include transform(translate3d(13px, 18px, 0px));
  }
}



.navigationButtonMiddleImageForwardsAnimation {
  -webkit-animation: navigationButtonMiddleImageAnimation 0.5s forwards ease-in-out;
  animation: navigationButtonMiddleImageAnimation 0.5s forwards ease-in-out;
}

@keyframes navigationButtonMiddleImageAnimation {
  0% {
    @include transform(translate3d(13px, 22px, 0px) rotate(0deg));
  }

  100% {
    @include transform(translate3d(13px, 22px, 0px) rotate(180deg));
  }
}

.navigationButtonMiddleImageReverseAnimation {
	-webkit-animation: navigationButtonMiddleImageReverseAnimation 0.5s forwards ease-in-out;
  animation: navigationButtonMiddleImageReverseAnimation 0.5s forwards ease-in-out;
}

@keyframes navigationButtonMiddleImageReverseAnimation {
  0% {
    @include transform(translate3d(13px, 22px, 0px) rotate(180deg));
  }

  100% {
    @include transform(translate3d(13px, 22px, 0px) rotate(0deg));
  }
}



.navigationButtonBottomImageAnimation {
  -webkit-animation: navigationButtonBottomImageAnimation 0.5s forwards ease-in-out;
  animation: navigationButtonBottomImageAnimation 0.5s forwards ease-in-out;
}

@keyframes navigationButtonBottomImageAnimation {
  0% {
    @include transform(translate3d(13px, 26px, 0px));
  }

  50% {
    @include transform(translate3d(13px, 35px, 0px));
  }

  100% {
    @include transform(translate3d(13px, 26px, 0px));
  }
}