.navigationButtonImageOneAnimation {
  -webkit-animation: slideToCurrentPage 0.5s forwards ease-in-out;
  animation: slideToCurrentPage 0.5s forwards ease-in-out;
}

.navigationButtonTopImageAnimation {
  0% {
    @include transform(translate3d(18px,18px,0px));
  }

  50% {
    @include transform(translate3d(18px,9px,0px));
  }

  100% {
    @include transform(translate3d(18px,18px,0px));
  }
}






/*
USED FOR LINE ANIMATION AROUND BUTTONS ON HOME PAGE
*/

@-webkit-keyframes clipIt {
   0%{
        -webkit-clip-path: inset(0px 0px 29px 0px);
        clip-path: inset(0px 0px 29px 0px);
    }

    25% {
         -webkit-clip-path: inset(0px 29px 0px 0px);
        clip-path: inset(0px 29px 0px 0px);
    }
    
    

    50% {
         -webkit-clip-path: inset(29px 0px 0px 0px);
        clip-path: inset(29px 0px 0px 0px);
    }

    75% {
         -webkit-clip-path: inset(0px 0px 0px 29px);
        clip-path: inset(0px 0px 0px 29px);
    }

    100% {
       -webkit-clip-path: inset(0px 0px 29px 0px);
        clip-path: inset(0px 0px 29px 0px);
    }
}
@-moz-keyframes clipIt {
   0%{
        -webkit-clip-path: inset(0px 0px 29px 0px);
        clip-path: inset(0px 0px 29px 0px);
    }

    25% {
         -webkit-clip-path: inset(0px 29px 0px 0px);
        clip-path: inset(0px 29px 0px 0px);
    }
    
    

    50% {
         -webkit-clip-path: inset(29px 0px 0px 0px);
        clip-path: inset(29px 0px 0px 0px);
    }

    75% {
         -webkit-clip-path: inset(0px 0px 0px 29px);
        clip-path: inset(0px 0px 0px 29px);
    }

    100% {
       -webkit-clip-path: inset(0px 0px 29px 0px);
        clip-path: inset(0px 0px 29px 0px);
    }
}
@-o-keyframes clipIt {
   0%{
        -webkit-clip-path: inset(0px 0px 29px 0px);
        clip-path: inset(0px 0px 29px 0px);
    }

    25% {
         -webkit-clip-path: inset(0px 29px 0px 0px);
        clip-path: inset(0px 29px 0px 0px);
    }
    
    

    50% {
         -webkit-clip-path: inset(29px 0px 0px 0px);
        clip-path: inset(29px 0px 0px 0px);
    }

    75% {
         -webkit-clip-path: inset(0px 0px 0px 29px);
        clip-path: inset(0px 0px 0px 29px);
    }

    100% {
       -webkit-clip-path: inset(0px 0px 29px 0px);
        clip-path: inset(0px 0px 29px 0px);
    }
}
@keyframes clipIt {
   0%{
        -webkit-clip-path: inset(0px 0px 29px 0px);
        clip-path: inset(0px 0px 29px 0px);
    }

    25% {
         -webkit-clip-path: inset(0px 29px 0px 0px);
        clip-path: inset(0px 29px 0px 0px);
    }
    
    

    50% {
         -webkit-clip-path: inset(29px 0px 0px 0px);
        clip-path: inset(29px 0px 0px 0px);
    }

    75% {
         -webkit-clip-path: inset(0px 0px 0px 29px);
        clip-path: inset(0px 0px 0px 29px);
    }

    100% {
       -webkit-clip-path: inset(0px 0px 29px 0px);
        clip-path: inset(0px 0px 29px 0px);
    }
}







