.page-slider-wrapper {
	width:100%;
	height:100%;
	//overflow:scroll;
}

.page-slider-slide.page-active {
	width: 100% !important;
	
	position:absolute;
	z-index:9;
	top:0px;
	height:100% !important;

	//overflow:hidden;

}

.page-slider-slide.page-inactive {
	width: 100%;
	position:absolute;
	z-index:100;

	top:0px;
	height:100%;
}



