.blockarrow-widget {
	@include transition(all, 0.15s, linear);
    width: 30px;
    height: 30px;
    color: #a2a1a1;
    border-radius: 3px;
    
    z-index:1000;
}

.blockarrow-widget::before{
	content: '';
    z-index: -1;

    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 1px;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;

    /*animation*/
    -webkit-animation: clipIt 4s linear infinite;
    -moz-animation: clipIt 4s linear infinite;
    animation: clipIt 4s linear infinite;
    -o-animation: clipIt 4s linear infinite;
}

/*.blockarrow-widget-widget::before {
	-webkit-animation-delay: -2s;
	animation-delay: -2s
}*/



@media only screen and (max-width: 680px) { 
    .blockarrow-widget {
         &:hover {
            @include transform(translate(-50%, -50%) rotate(45deg) scale(1.0));
            background-color:transparent;
            path {
                fill:white;
            }
        }
    }

}