.about {
	// overflow:scroll;
	-webkit-overflow-scrolling: touch;
	
	

}
.page-about {
	background: url('~/public/media/photography/meteor-compressor.jpg') no-repeat center center fixed; 
	background-attachment: fixed;
	overflow:scroll;
	width:100%;
	height:100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	.header-wrapper {
		display:flex;
		justify-content:center;
		align-items:center;
		flex-direction:column;
		height:100%;

		.header {
			height: auto;
			position:relative;
			
			.box {
				flex:none;
				float:left;
				width: 50px;
				height:50px;
				line-height: 50px;
				font-size:25px;
				color:#fff;
				text-align:center;
			}
		}
		.learnmore {
			@include hoverBoard();
			@include transition(box-shadow, 0.2s, linear);
			margin-top:100px;
			width:200px;
			height:50px;
			background-color:#77d277;
			text-align:center;
			line-height:50px;
			cursor:pointer;
		}
	}
	

	
	.section-whoweare {
		padding:20px 35px 20px 35px;
		box-sizing:border-box;
		background-color:transparent;
		width:80%;
		h1 {
			font-size:50px;
			font-family: 'Roboto Slab', serif;
			font-style: italic;
			margin-bottom:50px;
		}
		p1 {

			font-size:25px;
			color:#fff;
		}
	}


	.section-whatido {
		margin-top:400px;
		box-sizing:border-box;
		padding:20px 35px 20px 35px;
		background-color:transparent;
		width:100%;
		text-align:center;
		margin-top:200px;
		h1 {
			font-size:50px;
			font-family: 'Roboto Slab', serif;
			font-style: italic;
		}

		p1 {
			font-size:20px;
			color:#fff;
		}

		.package-wrapper {
			display:flex;
			flex-direction:row;
			flex-wrap:wrap;
			justify-content:center;
			div {
				width:15%;
				img {

					display: inherit;
					width:50px;
					margin:auto;
					margin-bottom:30px;
				}
			}
		}
	}

		.section-teammessage{
			width:100%;
			box-sizing:border-box;
			padding:20px 35px 20px 35px;
			text-align:right;
			margin-top:200px;
			h1 {
				font-size:40px;
				font-family: 'Roboto Slab', serif;
				font-style: italic;
			}
			p1 {
				font-size:25px;
			}
		}

	.section-contact {
		opacity:0.9;
		position:relative;
		background-color:transparent;
		margin-top:200px;
		margin-bottom:50px;
		padding:20px 35px 40px 35px;
		margin-left:35px;
		background-color:white;
		width:50%;
		max-width:500px;
		
		.fields {
			overflow:hidden;
			display:flex;
			flex-direction:column;
			margin-bottom:30px;
		}
		h1 {
			color: #77D277;
			font-family: 'Roboto Slab', serif;
			font-style: italic;
			position:absolute;
			background-color:white;
			box-shadow:5px 5px 25px 0px rgba(46,61,73,0.2);
			top:-31px;
			padding:0px 20px;
			white-space:nowrap;
		}
		
		.submit {
			@include hoverBoard();
			@include transition(box-shadow, 0.2s, linear);
			width:170px;
			height:50px;
			position:absolute;
			right:35px;
			bottom:-25px;
			cursor:pointer;
			background-color:#77D277;
			color:white;
			text-align:center;
			line-height:50px;
			img {
				width:40px;
				height:50px;
				margin:auto;
			}

		}

	}

}


















@media only screen and (max-width: 850px) { 
	.box {
		width:40px;
		height:40px;
		line-height:40px;
	}
}

@media only screen and (max-width: 680px) { 
	.box {
		width:50px;
		height:50px;
		line-height:50px;
	}
	.header {
		
	}



}
@media only screen and (max-width: 744px) { 
	.page-about .section-whatido {
		
		
		.package-wrapper {
			div {
				width:25%;
			}
		}
	}
}


@media only screen and (max-width: 544px) { 
	.page-about .section-whoweare {
		width:100%;
		h1 {
			font-size:40px;
			white-space:nowrap;
		}
		p1 {
			font-size:20px;
		}
	}

	.page-about .section-whatido {
		width:100%;
		h1 {
			font-size:40px;
			white-space:nowrap;
		}

		p1 {
			font-size:15px;
		}
		
		.package-wrapper {
			div {
				width:33%;
			}
			div img {
				width:40px;
			}
		}
	}

	.page-about .section-teammessage {
		h1 {
			font-size:25px;
			line-height:50px
		}

		p1 {
			font-size:15px;
		}
	}

	.page-about .section-contact {
		h1 {
			left:50%;
			transform:translateX(-50%)
		}

		.submit {
			left:50%;
			transform:translateX(-50%);
		}
	}
}
  