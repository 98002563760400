.subscribe {
	.iron-image-loaded {
		background-position:center;
	}
}

.page-subscribe {
	width:100%;
	height:100%;
	background-color: #54577C;


	.arrow-holder {
		position: absolute;
		left:50%;
		transform: translateX(-50%);
		bottom:20px;
		.blockarrow-widget {
			
			@include transform(rotate(225deg));

			svg {
			    position: absolute;
			    @include transform(scale(0.75) rotate(-45deg));
			}
			
		 	&:hover {
		 		background-color:white;
				path {
					fill:#54577C;
				}
		 	}
		}
	}

	

	.form {
		width:75%;
		margin:auto;
	    display:flex;
	    justify-content:center;
	    flex-direction:column;
	    height:100%;
	    align-items:center;
		h1 {

		}
		
		.fields {
			display:flex;
			flex-direction:column;
			width:250px;
			input {
				width: 100%;
			    height: 50px;
			    background-color: transparent;
			    border-bottom: solid 1px white;
			    font-size:20px;
			    color:white;
			    font-family: 'Roboto', sans-serif;
			}
			input::placeholder {
				color:white;
				font-size:20px;
			}
		}

		.submit {
			@include transition(all, 0.2s, linear);
			font-family: 'Roboto', sans-serif;
			margin-top:40px;
			width: 185px;
		    height: 50px;
		    background-color: #82c8c9;
		    background: #70e1f5;  /* fallback for old browsers */
			background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);  /* Chrome 10-25, Safari 5.1-6 */
			background: linear-gradient(to right, #ffd194, #70e1f5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
			background: linear-gradient(-45deg, rgba(120, 108, 68, 0.6784313725490196) 0%, rgba(93, 84, 51, 0.55) 50%, rgba(93, 84, 66, 0.5) 51%);
			
		    border-radius: 27px;
		    box-shadow: black;
		    @include hoverBoard();
		    cursor:pointer;
		    
		    text-align:center;
		    line-height:50px;

		    img {
		    	width:40px;
		    	height:50px;
		    	margin:auto;
		    }
		}
	}


	.error {
		position: absolute;
	    width: 250px;
	    bottom: 10px;
	    display: flex;
	    flex-direction: row;
	    left: 50%;
	    align-items: center;
	    justify-content: center;
	    @include transform(translateX(-50%));
	   
	    img {
	    	width: 20px;
		    float: left;
		    margin-right: 10px;
		}

		p1 {
			font-family: 'Roboto', sans-serif;
    		text-align: left;
    		font-size: 12px;
		}
	}
}
@media only screen and (max-width: 545px) { 
	.page-form .overlay {
		width:350px;
	}
}